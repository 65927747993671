import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";

import "./App.css";

function App() {
  const [urls, setUrls] = useState([]);
  const [currentUrl, setCurrentUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isValidUrl = (urlString) => {
    var inputElement = document.createElement("input");
    inputElement.type = "url";
    inputElement.value = urlString;

    if (!inputElement.checkValidity()) {
      return false;
    } else {
      return true;
    }
  };

  const onTextFieldChange = (e) => {
    setCurrentUrl(e.target.value.trim());
  };

  const onTextFieldKeyPress = (e) => {
    if (
      e.keyCode === 13 &&
      currentUrl !== "" &&
      urls.indexOf(currentUrl) === -1 &&
      isValidUrl(currentUrl)
    ) {
      setUrls((prev) => [...prev, currentUrl]);
      setCurrentUrl("");
    }
  };

  const onDelete = (url) => {
    setUrls([...urls].filter((e) => e !== url));
  };

  const onDownload = async () => {
    setIsLoading(true);
    const response = await fetch(
      "https://reparcs-atsni-kw2an5scka-ew.a.run.app/instagram/multiple/download",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          urls: urls,
        }),
      }
    );
    const data = await response.json();
    let alink = document.createElement("a");
    alink.href = data.url;
    alink.download = "content.zip";
    alink.click();
    setIsLoading(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <Typography variant="h3" component="h3">
          Content downloader
        </Typography>
        <Box sx={{ m: 2 }} />
        <Typography variant="h5" component="h5">
          Add instagram post/reel url
        </Typography>
        <TextField
          value={currentUrl}
          onKeyDown={onTextFieldKeyPress}
          onChange={onTextFieldChange}
          style={{ color: "white", width: 500 }}
          inputProps={{
            style: { color: "white" },
          }}
          color="success"
          focused
        />
        <List>
          {urls.map((url) => (
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  color="warning"
                  onClick={() => onDelete(url)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={url} />
            </ListItem>
          ))}
        </List>
        {urls.length > 0 && (
          <Button onClick={onDownload} variant="contained">
            Download
          </Button>
        )}
        <Backdrop sx={{ color: "#fff", zIndex: 1001 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </header>
    </div>
  );
}

export default App;
